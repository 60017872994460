import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import { usersApi } from 'src/api/user';
interface Device {
  device_token: string;
  manufacturer_id: string;
  arn: string;
  _id: string;
}

interface User {
  _id: string;
  phone_number: string;
  last_login_date: string | null;
  blocked_users: string[];
  deleted: boolean;
  sign_up_date: string;
  tribes: string[];
  devices: Device[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  avatar_url: string;
  bio: string;
  birthday: string;
  email: string;
  full_name: string;
  stripe_account_status: string;
  role: string;
}

interface Habit {
  category: string;
}

export interface TribeDetail {
  _id: string;
  leader_name: string;
  leader_id: string;
  leader_avatar_url: string;
  tribe_name: string;
  account_status: string;
  join_status: string;
  role: string;
  members: any;
  status: string;
  tribe_avatar_url: string;
  price: number;
  member_count_max: number;
  habits: Habit[];
  description: string;
  id?: string;
  postLenght?: number;
}

interface ApiResponse {
  result: User;
  tribe_details: TribeDetail[];
  comment_count: number;
  like_count: number;
}

interface LoggedUserState {
  user: User | null;
  tribeDetails: TribeDetail[];
  commentCount: number;
  likeCount: number;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  initialized: boolean;
}

const initialState: LoggedUserState = {
  user: null,
  tribeDetails: [],
  commentCount: 0,
  likeCount: 0,
  status: 'idle',
  error: null,
  initialized: false,
};

export const fetchLoggedUser = createAsyncThunk<ApiResponse>(
  'user/fetchLoggedUser',
  async () => {
    const response = await usersApi.userMe();
    return response.data;
  }
);

const loggedUserSlice = createSlice({
  name: 'loggedUser',
  initialState,
  reducers: {
    removeCircleById: (state, action) => {
      state.tribeDetails = state.tribeDetails.filter(
        (tribe) => tribe._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoggedUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchLoggedUser.fulfilled,
        (state, action: PayloadAction<ApiResponse>) => {
          state.status = 'succeeded';
          state.user = action.payload.result;
          state.tribeDetails = action.payload.tribe_details;
          state.commentCount = action.payload.comment_count;
          state.likeCount = action.payload.like_count;
          state.initialized = true;
        }
      )
      .addCase(fetchLoggedUser.rejected, (state, action) => {
        state.status = 'failed';
        state.initialized = true;
        state.error = action.error.message || 'Failed to load user data';
      });
  },
});

export const selectCurrentUser = (state: { loggedUser: LoggedUserState }) =>
  state.loggedUser.user;
export const selectTribeDetails = (state: { loggedUser: LoggedUserState }) =>
  state.loggedUser.tribeDetails;
export const selectCommentCount = (state: { loggedUser: LoggedUserState }) =>
  state.loggedUser.commentCount;
export const selectLikeCount = (state: { loggedUser: LoggedUserState }) =>
  state.loggedUser.likeCount;
export const selectLoggedUserInitialized = (state: {
  loggedUser: LoggedUserState;
}) => state.loggedUser.initialized;
export const selectCurrentUserId = (state: { loggedUser: LoggedUserState }) =>
  state.loggedUser.user?._id || null;

export const selectFullUserInfo = createSelector(
  [
    (state: { loggedUser: LoggedUserState }) => state.loggedUser.user,
    (state: { loggedUser: LoggedUserState }) => state.loggedUser.commentCount,
    (state: { loggedUser: LoggedUserState }) => state.loggedUser.likeCount,
    (state: { loggedUser: LoggedUserState }) => state.loggedUser.tribeDetails,
  ],
  (user, commentCount, likeCount, tribeDetails) =>
    user
      ? {
          result: user,
          comment_count: commentCount,
          like_count: likeCount,
          tribe_details: tribeDetails,
        }
      : null
);

export const { removeCircleById } = loggedUserSlice.actions;

export default loggedUserSlice.reducer;
