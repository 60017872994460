import { FC } from 'react';
import classNames from 'classnames';
import styles from './CategoryItem.module.scss';

interface CategoryItemProps {
  item: { name: string; img?: string; img_ligh?: string };
  isSelected?: boolean;
  onClick?: () => void;
  disablePointer?: boolean;
}

export const CategoryItem: FC<CategoryItemProps> = ({
  item,
  isSelected,
  onClick,
  disablePointer = false,
}) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div
        className={classNames(styles.block, { [styles.selected]: isSelected })}
        style={{ cursor: disablePointer ? 'default' : 'pointer' }}
      >
        <img
          src={isSelected ? item.img_ligh : item.img}
          width={17}
          height={17}
          alt=""
        />
        {item.name}
      </div>
    </div>
  );
};
