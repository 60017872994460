import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { circleApi } from 'src/api/circles';
import { auctionApi } from 'src/api/auctionApi';
import { messagesApi } from 'src/api/messagesApi';
import { VideoMessagesResponse } from 'src/pages/Home/Tabs/types';
import { RootState } from 'src/redux/store/store';

interface Habit {
  name: string;
  category: string;
}

export interface TribeDetail {
  _id: string;
  createdAt: string;
  leader_name: string;
  leader_id: string;
  leader_avatar_url: string;
  tribe_name: string;
  account_status: string;
  join_status: string;
  role: string;
  members: any;
  status: string;
  tribe_avatar_url: string;
  price: number;
  member_count_max: number;
  habits: Habit[];
  description: string;
  id?: string;
  postLenght?: number;
  categories: string[];
  welcome_video_url: string;
  welcome_video_id: string;
  account_type: string;
}

interface CircleDetailState {
  loading: boolean;
  circleData: TribeDetail | null;
  videoUrl: string;
  auctionId: string;
  auctionData: any;
  videoMessages: VideoMessagesResponse;
  error: string | null;
  memberMessages: VideoMessagesResponse;
  loadingMemberMessages: boolean;
}

const initialState: CircleDetailState = {
  loading: false,
  circleData: null,
  videoUrl: '',
  auctionId: '',
  auctionData: null,
  memberMessages: {
    result: [],
    replies: [],
    totalPages: 0,
    currentpage: 1,
  },
  loadingMemberMessages: false,
  videoMessages: {
    result: [],
    replies: [],
    totalPages: 0,
    currentpage: 1,
  },
  error: null,
};

export const fetchCircleBase = createAsyncThunk(
  'circleDetail/fetchCircleBase',
  async (id: string) => {
    const circleRes = await circleApi.getCircle(id);
    return {
      circleData: circleRes.data.result,
      videoUrl: circleRes.data.result.welcome_video_id,
    };
  }
);

export const fetchMessagesForMember = createAsyncThunk(
  'circleDetail/fetchMessagesForMember',
  async (_, { rejectWithValue }) => {
    try {
      const res = await messagesApi.getMessagesMember();
      return res.data;
    } catch (error: any) {
      return rejectWithValue('Failed to fetch member messages');
    }
  }
);

export const fetchAuctionIfLeader = createAsyncThunk(
  'circleDetail/fetchAuctionIfLeader',
  async (id: string, { rejectWithValue }) => {
    try {
      const auctionRes = await auctionApi.getAuctionList(id);
      const auction = auctionRes.data.results?.[0] ?? null;
      return {
        auctionId: auction?._id ?? '',
        auctionData: auction,
      };
    } catch (error: any) {
      if (error.response?.status === 403) {
        return rejectWithValue('Not authorized to view auction data.');
      }
      throw error;
    }
  }
);

export const fetchCircleMessagesIfLeader = createAsyncThunk(
  'circleDetail/fetchCircleMessagesIfLeader',
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await messagesApi.getMessagesLeader(id);
      return res.data;
    } catch (error: any) {
      if (error.response?.status === 403) {
        return rejectWithValue('Not authorized to view messages.');
      }
      throw error;
    }
  }
);

export const updateCircle = createAsyncThunk(
  'circleDetail/updateCircle',
  async (
    { id, updates }: { id: string; updates: Record<string, any> },
    { rejectWithValue }
  ) => {
    try {
      await circleApi.etidCirclePatch(updates, id);
      return updates;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    }
  }
);

export const editUserCount = createAsyncThunk(
  'circleDetail/editUserCount',
  async (
    { id, member_count_max }: { id: string; member_count_max: number },
    { rejectWithValue }
  ) => {
    try {
      await circleApi.editUserCount(id, member_count_max);
      return { id, member_count_max };
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || 'Failed to edit user count'
      );
    }
  }
);

const circleDetailSlice = createSlice({
  name: 'circleDetail',
  initialState,
  reducers: {
    clearCircleDetail: (state) => {
      Object.assign(state, initialState);
    },
    addVideoMessage: (state, action) => {
      state.videoMessages.result.unshift(action.payload);
    },
    addReplyMessage: (state, action) => {
      state.memberMessages.replies.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCircleBase.pending, (state) => {
        Object.assign(state, initialState);
        state.loading = true;
      })
      .addCase(fetchCircleBase.fulfilled, (state, action) => {
        state.circleData = action.payload.circleData;
        state.videoUrl = action.payload.videoUrl;
        state.loading = false;
      })
      .addCase(fetchCircleBase.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAuctionIfLeader.fulfilled, (state, action) => {
        state.auctionId = action.payload.auctionId;
        state.auctionData = action.payload.auctionData;
      })
      .addCase(fetchCircleMessagesIfLeader.fulfilled, (state, action) => {
        state.videoMessages = action.payload;
      })
      .addCase(updateCircle.pending, (state) => {
        state.error = null;
      })
      .addCase(updateCircle.fulfilled, (state, action) => {
        if (state.circleData) {
          state.circleData = { ...state.circleData, ...action.payload };
        }
        state.loading = false;
      })
      .addCase(updateCircle.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || 'Failed to update circle details';
      })
      .addCase(editUserCount.pending, (state) => {
        state.error = null;
      })
      .addCase(editUserCount.fulfilled, (state, action) => {
        if (state.circleData) {
          state.circleData.member_count_max = action.payload.member_count_max;
        }
        state.loading = false;
      })
      .addCase(editUserCount.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || 'Failed to edit user count';
      })
      .addCase(fetchMessagesForMember.pending, (state) => {
        state.loadingMemberMessages = true;
      })
      .addCase(fetchMessagesForMember.fulfilled, (state, action) => {
        state.memberMessages = action.payload;
        state.loadingMemberMessages = false;
      })
      .addCase(fetchMessagesForMember.rejected, (state) => {
        state.loadingMemberMessages = false;
      });
  },
});

export const { clearCircleDetail, addVideoMessage, addReplyMessage } =
  circleDetailSlice.actions;

export const selectCircleDetailsData = (state: RootState) =>
  state.circleDetail.circleData;
export const selectAuctionDetailsData = (state: RootState) =>
  state.circleDetail.auctionData;
export const selectCircleDetailLoading = (state: RootState) =>
  state.circleDetail.loading;
export const selectCircleDetailError = (state: RootState) =>
  state.circleDetail.error;

export default circleDetailSlice.reducer;
