import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postsApi } from 'src/api/postst';

interface Challenge {
  _id: string;
  name: string;
  category: string;
}

interface Post {
  _id: string;
  tribe_name: string;
  leader_name: string;
  video_id: string;
  video_url: string;
  challenges: Challenge[];
  comment_count: number;
  createdAt: string;
  comments: any;
}

export interface FeedState {
  posts: Post[];
  currentPage: number;
  totalPages: number;
  isLoading: boolean;
  isFetchingMore: boolean;
  error: string | null;
}

const initialState: FeedState = {
  posts: [],
  currentPage: 1,
  totalPages: 1,
  isLoading: false,
  isFetchingMore: false,
  error: null,
};

export const fetchPosts = createAsyncThunk(
  'feed/fetchPosts',
  async ({ page }: { page: number }) => {
    const response = await postsApi.getAllPosts({
      page,
      pageSize: 10,
      videoRequired: true,
    });
    return response.data;
  }
);

export const fetchPostsById = createAsyncThunk(
  'feed/fetchPostsById',
  async ({ id, page }: { id: string; page: number }) => {
    const response = await postsApi.getPostsForCircle(id, page);
    return { data: response.data, page };
  }
);

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    clearPosts: (state) => {
      state.posts = [];
      state.currentPage = 1;
      state.totalPages = 1;
      state.isLoading = false;
      state.isFetchingMore = false;
      state.error = null;
    },
    addPost: (state, action) => {
      state.posts = [action.payload, ...state.posts];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state, action) => {
        if (action.meta.arg.page === 1) {
          state.isLoading = true;
        } else {
          state.isFetchingMore = true;
        }
        state.error = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        const { result, totalPages } = action.payload;
        state.posts =
          action.meta.arg.page === 1 ? result : [...state.posts, ...result];
        state.totalPages = totalPages;
        state.isLoading = false;
        state.isFetchingMore = false;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch posts';
        state.isLoading = false;
        state.isFetchingMore = false;
      })
      .addCase(fetchPostsById.pending, (state, action) => {
        if (action.meta.arg.page === 1) {
          state.isLoading = true;
        } else {
          state.isFetchingMore = true;
        }
        state.error = null;
      })
      .addCase(fetchPostsById.fulfilled, (state, action) => {
        const { data, page } = action.payload;
        state.posts =
          page === 1 ? data.result : [...state.posts, ...data.result];
        state.totalPages = data.totalPages;
        state.currentPage = page;
        state.isLoading = false;
        state.isFetchingMore = false;
      })
      .addCase(fetchPostsById.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch posts';
        state.isLoading = false;
        state.isFetchingMore = false;
      });
  },
});

export const { clearPosts, addPost } = feedSlice.actions;
export default feedSlice.reducer;
