import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { CircularProgress } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { circleApi } from 'src/api/circles';
import styles from './BulkAddMembersModal.module.scss';

interface BulkAddMembersModalProps {
  setShowBulkAddMembers: (show: boolean) => void;
  circleId: string;
  fetchMembers: () => void;
}

interface UploadResults {
  added: number;
  existing: number;
  failed: number;
  invalidUsers?: { email: string; phone_number: string }[];
  message?: string;
  errors?: Array<{
    user: {
      email?: string;
      phone_number?: string;
    };
    error: string;
  }>;
}

export const BulkAddMembersModal: FC<BulkAddMembersModalProps> = ({
  setShowBulkAddMembers,
  circleId,
  fetchMembers,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [preview, setPreview] = useState<Array<Record<string, string>>>([]);
  const [results, setResults] = useState<UploadResults | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
    setError(null);
    setResults(null);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const csv = event.target?.result as string;
          const lines = csv.split('\n');
          const headers = lines[0].split(',').map((h) => h.trim());

          // Preview first 5 rows
          const previewData = lines
            .slice(1, Math.min(lines.length, 6))
            .map((line) => {
              if (!line.trim()) return null; // Skip empty lines
              const values = line.split(',').map((v) => v.trim());
              return headers.reduce(
                (obj, header, i) => {
                  obj[header] = values[i] || '';
                  return obj;
                },
                {} as Record<string, string>
              );
            })
            .filter(Boolean) as Record<string, string>[];

          setPreview(previewData);
        } catch (err) {
          setError('Error parsing CSV file');
          setPreview([]);
        }
      };
      reader.readAsText(selectedFile);
    } else {
      setPreview([]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a CSV file');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const csvData = e.target?.result as string;

        try {
          const response = await circleApi.bulkAddMembers(circleId, {
            csvData,
          });
          setResults(response.data.results);
          fetchMembers();
          setLoading(false);
        } catch (err: any) {
          setError(err.response?.data?.message || 'Error uploading users');
          setLoading(false);
        }
      };

      reader.readAsText(file);
    } catch (err) {
      setError('Error reading file');
      setLoading(false);
    }
  };

  const handleReset = () => {
    setFile(null);
    setPreview([]);
    setResults(null);
    setError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div onClick={() => setShowBulkAddMembers(false)}>
          <KeyboardBackspaceIcon sx={{ color: 'black', cursor: 'pointer' }} />
        </div>
        <h2>Bulk Add Members</h2>
      </div>

      <div className={styles.instructions}>
        <h3>Instructions:</h3>
        <p>Upload a CSV file with email and/or phone number columns.</p>
        <p>
          The CSV must have a header row with columns named "email" and/or
          "phone_number".
        </p>
        <p>
          Phone number must have valid country code and area code like this
          +16047888888
        </p>
        <div className={styles.exampleContainer}>
          <p>Example CSV format:</p>
          <div className={styles.example}>
            <table className={styles.exampleTable}>
              <thead>
                <tr>
                  <th>email</th>
                  <th>phone_number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>john@example.com</td>
                  <td>+15551234567</td>
                </tr>
                <tr>
                  <td>alice@example.com</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>+15557890123</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={styles.uploadSection}>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          disabled={loading}
          ref={fileInputRef}
          className={styles.fileInput}
        />
        {file && <p className={styles.fileName}>Selected file: {file.name}</p>}
      </div>

      {preview.length > 0 && (
        <div className={styles.previewContainer}>
          <h3>Preview (first 5 rows):</h3>
          <div className={styles.tableWrapper}>
            <table className={styles.previewTable}>
              <thead>
                <tr>
                  {Object.keys(preview[0]).map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {preview.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, i) => (
                      <td key={i}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {error && <div className={styles.errorMessage}>{error}</div>}

      {results && (
        <div className={styles.resultsContainer}>
          <h3>Upload Results:</h3>
          <div className={styles.resultsSummary}>
            <div className={styles.resultItem}>
              <span className={styles.resultLabel}>Added</span>
              <span className={styles.resultValue}>{results.added}</span>
            </div>
            <div className={styles.resultItem}>
              <span className={styles.resultLabel}>Existing</span>
              <span className={styles.resultValue}>{results.existing}</span>
            </div>
            <div className={styles.resultItem}>
              <span className={styles.resultLabel}>Failed</span>
              <span className={styles.resultValue}>{results.failed}</span>
            </div>
          </div>

          {results.errors && results.errors.length > 0 && (
            <div className={styles.errorDetails}>
              <h4>Errors:</h4>
              <ul className={styles.errorList}>
                {results.errors.map((err, index) => (
                  <li key={index}>
                    <strong>{err.user.email || err.user.phone_number}:</strong>{' '}
                    {err.error}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {results.invalidUsers && results.invalidUsers.length > 0 && (
            <div className={styles.invalidUsers}>
              <h4>Invalid Users:</h4>
              <ul className={styles.invalidUsersList}>
                {results.invalidUsers.map((user, index) => (
                  <li key={index}>
                    <strong>{user.email || user.phone_number}:</strong>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      <div className={styles.actionButtons}>
        {!results ? (
          <>
            <button
              className={styles.uploadButton}
              onClick={handleUpload}
              disabled={!file || loading}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                'Upload and Add Members'
              )}
            </button>
            {file && (
              <button
                className={styles.resetButton}
                onClick={handleReset}
                disabled={loading}
              >
                Reset
              </button>
            )}
          </>
        ) : (
          <button
            className={styles.doneButton}
            onClick={() => setShowBulkAddMembers(false)}
          >
            Done
          </button>
        )}
      </div>
    </div>
  );
};
