import { FC, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { MuiSelect } from 'src/components/material/MuiSelect';
import styles from '../AuctionTime/AuctionTime.module.scss';

interface AuctionSizeProps {
  size: string;
  setSize: (value: string) => void;
}

export const AuctionSize: FC<AuctionSizeProps> = ({ size, setSize }) => {
  const maxAuctionSize = useMemo(() => Math.min(147, 147), []);

  const AUCTION_SIZE_OPTIONS = useMemo(
    () =>
      Array.from({ length: maxAuctionSize - 9 }, (_, i) => ({
        label: (i + 10).toString(),
        value: (i + 10).toString(),
      })),
    [maxAuctionSize]
  );
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Auction size</div>
        {/* <div>
          <InfoOutlinedIcon style={{ color: 'red' }} />
        </div> */}
      </div>
      {/* <div className={styles.description}>
        Set the maximum number of Members you wish to admit to your Circle now.
        You can admit more Members later.
      </div> */}
      <div className={styles.wrapper}>
        <MuiSelect
          label="Duration"
          value={size}
          options={AUCTION_SIZE_OPTIONS}
          onChange={(event: SelectChangeEvent) => setSize(event.target.value)}
          width="100%"
        />
      </div>
      {/* <div className={styles.charactersLength}>
        Choose a number from 1 to 148
      </div> */}
    </div>
  );
};
