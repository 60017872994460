import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { UploadIcon } from 'src/assets/icons/upload-icon';
import styles from './Video.module.scss';
import { usersApi } from 'src/api/user';
import { circleApi } from 'src/api/circles';

interface UploadVideoProps {
  defaultVideo: string;
  onUpdate: (fileId: string | File) => void;
  classNameVideoContainer?: string;
  children?: React.ReactNode;
  helperText?: string;
}

export const UploadVideo: FC<UploadVideoProps> = ({
  defaultVideo,
  onUpdate,
  classNameVideoContainer,
  children,
  helperText,
}) => {
  const [previewVideo, setPreviewVideo] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (defaultVideo) {
      setPreviewVideo(defaultVideo);
    }
  }, [defaultVideo]);

  const handleVideoClick = () => {
    fileRef.current?.click();
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0];
    if (uploadedFile) await handleFileUpload(uploadedFile);
  };

  const handleFileUpload = async (file: File) => {
    if (file.size > 104857600) {
      alert('Video size should not exceed 100MB');
      return;
    }

    setIsUploading(true);
    try {
      const response = await usersApi.uploadFile({
        contentType: file.type,
        name: file.name,
        type: 'video-welcome',
      });
      const { uploadURL, Key } = response.data;

      const uploadResponse = await fetch(uploadURL, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file,
      });

      const getVideoId = await circleApi.getVideoId(Key);

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed with status: ${uploadResponse.status}`);
      }

      const previewUrl = URL.createObjectURL(file);
      setPreviewVideo(previewUrl);
      onUpdate(getVideoId.data._id);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file. Check the console for more details.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) await handleFileUpload(file);
  };

  const handleIconClick = () => {
    navigate(`/how-it-works#info-video`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Video</div>
        <div onClick={handleIconClick} className={styles.infoIcon}>
          <InfoOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} />
        </div>
      </div>
      <div className={styles.description}>
        Similar to the Description, this is your chance to pitch the purpose and
        value of your Circle.
      </div>
      <div
        className={classNames(styles.videoContainer, classNameVideoContainer, {
          [styles.dragging]: isDragging,
        })}
        onClick={handleVideoClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {!previewVideo && !isUploading && (
          <div className={styles.videoWrapper}>
            <div>
              <div className={styles.icon}>
                <UploadIcon />
              </div>
              <div className={styles.uploadText}>Select video to upload</div>
              <div className={styles.dragText}>Or drag and drop it here</div>
            </div>
            <button
              type="button"
              className={styles.selectButton}
              onClick={handleVideoClick}
            >
              Select video
            </button>
          </div>
        )}
        {isUploading && <div className={styles.uploading}>Uploading...</div>}
        {previewVideo && !isUploading && (
          <video
            src={previewVideo}
            controls
            className={styles.previewVideo}
            style={{ width: '100%', height: '350px', display: 'block' }}
          >
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className={styles.maxDurationText}>Maximum 60 seconds</div>
      <input
        accept=".mp4, .mov, .avi"
        type="file"
        className={styles.hiddenInput}
        ref={fileRef}
        onChange={handleInputChange}
      />
    </div>
  );
};
