import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postsApi } from 'src/api/postst';
import { RootState } from 'src/redux/store/store';

interface Challenge {
  _id: string;
  name: string;
  category: string;
}

interface MediaParams {
  width: number;
  time?: number;
  start?: number;
}

interface Media {
  params: MediaParams;
  url: string;
}

interface Post {
  _id: string;
  tribe_id: string;
  tribe_name: string;
  leader_id: string;
  leader_name: string;
  video_id: string;
  video_url: string;
  hidden_by: string[];
  challenges: Challenge[];
  comments: string[];
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  thumbnail: Media;
  animated_gif: Media;
  comment_count: number;
}

interface PostsState {
  posts: Post[];
  loading: boolean;
  error: string | null;
}

const initialState: PostsState = {
  posts: [],
  loading: false,
  error: null,
};

// // Async thunk to fetch all posts
// export const fetchAllPosts = createAsyncThunk(
//   'posts/fetchAllPosts',
//   async (circleId: string) => {
//     const response = await postsApi.getPostsForCircle(circleId);
//     const uniqueData = response.data.result.filter(
//       (item: any, index: any, self: any) =>
//         index === self.findIndex((t: any) => t._id === item._id)
//     );
//     return uniqueData;
//   }
// );

// Async thunk to add a post
export const addPost = createAsyncThunk(
  'posts/addPost',
  async (postData: {
    tribe_id: string;
    leader_id: string;
    leader_name: string;
    challenges: { name: string; category: string }[];
    video_id: string;
  }) => {
    const response = await postsApi.postChallenge({
      ...postData,
      datePosted: new Date(),
      commentIds: [],
      commentCount: 0,
    });
    return response.data;
  }
);

// Async thunk to fetch posts by circle ID with pagination
export const fetchPostsByCircleId = createAsyncThunk(
  'posts/fetchPostsByCircleId',
  async ({ id, page }: { id: string; page: number }) => {
    const response = await postsApi.getPostsForCircle(id, page);
    return {
      data: response.data.result,
      totalPages: response.data.totalPages,
      page,
    };
  }
);

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(fetchAllPosts.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(
      //   fetchAllPosts.fulfilled,
      //   (state, action: PayloadAction<Post[]>) => {
      //     state.posts = action.payload;
      //     state.loading = false;
      //   }
      // )
      // .addCase(fetchAllPosts.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message || 'Failed to fetch posts';
      // })
      .addCase(addPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPost.fulfilled, (state, action: PayloadAction<Post>) => {
        state.posts.unshift(action.payload); // Add new post to the beginning of the list
        state.loading = false;
      })
      .addCase(addPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to add post';
      })
      .addCase(fetchPostsByCircleId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchPostsByCircleId.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: Post[];
            totalPages: number;
            page: number;
          }>
        ) => {
          state.posts =
            action.payload.page === 1
              ? action.payload.data
              : [...state.posts, ...action.payload.data];
          state.loading = false;
        }
      )
      .addCase(fetchPostsByCircleId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch posts';
      });
  },
});

export const selectAllPosts = (state: RootState) => state.posts.posts;
export const postsLoading = (state: RootState) => state.posts.loading;
export const postsError = (state: RootState) => state.posts.error;

export default postsSlice.reducer;
