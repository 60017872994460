import Avatar from '@mui/material/Avatar';
import styles from './Header.module.scss';

export const Header = ({
  leader_name,
  tribe_name,
  tribe_avatar_url,
  price,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.user}>
        <div className={styles.avatar}>
          <Avatar src={tribe_avatar_url} sx={{ width: 55, height: 55 }}>
            N
          </Avatar>
        </div>
        <div className={styles.info}>
          <div className={styles.data}>
            <div className={styles.title}>{tribe_name}</div>
            <div className={styles.name}>{leader_name}</div>
          </div>
        </div>
      </div>
      <div className={styles.price}>${price} / month</div>
    </div>
  );
};
