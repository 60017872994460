import { FC, useState, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment, { Moment } from 'moment';
import { MuiModal } from 'src/components/material/MuiModal';
import { MuiSelect } from 'src/components/material/MuiSelect';
import styles from './CreateAuctionModal.module.scss';
import { DURATION_OPTIONS, dateTimeStyles } from './const';
import { auctionApi } from 'src/api/auctionApi';
import { useNavigate } from 'react-router-dom';

interface CreateAuctionModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  circleId: string;
  circleName: string;
  leaderName: string;
  tribeAvatar: string;
}

export const CreateAuctionModal: FC<CreateAuctionModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  circleId,
  circleName,
  leaderName,
  tribeAvatar,
}) => {
  const navigate = useNavigate();

  const [duration, setDuration] = useState<string>('148');

  const [auctionStartMode, setAuctionStartMode] = useState<
    'immediately' | 'with-delay'
  >('immediately');

  const [startDate, setStartDate] = useState<Moment>(moment());
  const [auctionSize, setAuctionSize] = useState<string>('20');

  // const currentMemberCount = 30;

  const maxAuctionSize = useMemo(
    () => Math.min(147, 147),
    []
    // [currentMemberCount]
  );

  const AUCTION_SIZE_OPTIONS = useMemo(
    () =>
      Array.from({ length: maxAuctionSize - 9 }, (_, i) => ({
        label: (i + 10).toString(),
        value: (i + 10).toString(),
      })),
    [maxAuctionSize]
  );

  const endDate = useMemo(
    () =>
      moment(startDate)
        .add(Number(duration), 'hours')
        .format('D MMM, HH:mm YYYY'),
    [startDate, duration]
  );

  const startAuction = () => {
    const openDate = auctionStartMode === 'immediately' ? moment() : startDate;
    const closeDate = moment(openDate).add(Number(duration), 'hours');

    const data = {
      // tribe_id: circleId,
      // tribe_avatar: tribeAvatar,
      // tribe_name: circleName,
      // leader_name: leaderName,
      open_seat_count: Number(auctionSize),
      openDate: openDate.toDate(),
      closeDate: closeDate.toDate(),
    };

    auctionApi.createAuction(circleId, data).then((res: any) => {
      const auctionId = res.data.result._id;
      const auctionLink = `/auction/${auctionId}`;
      navigate(auctionLink, { replace: true });
    });
  };
  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={400}>
      <div className={styles.title}>Create an Auction</div>

      <div className={styles.wrapper}>
        <div className={styles.label}>Choose Auction Start Time</div>
        <RadioGroup
          value={auctionStartMode}
          onChange={(event) =>
            setAuctionStartMode(
              event.target.value as 'immediately' | 'with-delay'
            )
          }
        >
          <FormControlLabel
            value="with-delay"
            control={<Radio />}
            label="With Delay"
          />
          <FormControlLabel
            value="immediately"
            control={<Radio />}
            label="Immediately"
          />
        </RadioGroup>
      </div>

      {auctionStartMode === 'with-delay' && (
        <div className={styles.wrapper}>
          <div className={styles.label}>Start Date</div>
          <DateTimePicker
            value={startDate}
            onChange={(newDate) => newDate && setStartDate(moment(newDate))}
            views={['year', 'month', 'day', 'hours']}
            slotProps={dateTimeStyles}
            sx={dateTimeStyles.sx}
          />
        </div>
      )}

      <div className={styles.wrapperMiddle}>
        <div className={styles.label}>Duration (in hours)</div>
        <MuiSelect
          label="Duration"
          value={duration}
          options={DURATION_OPTIONS}
          onChange={(event: SelectChangeEvent) =>
            setDuration(event.target.value)
          }
          width="100%"
        />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.label}>Auction Size</div>
        <MuiSelect
          label="Auction size"
          value={auctionSize}
          options={AUCTION_SIZE_OPTIONS}
          onChange={(event: SelectChangeEvent) =>
            setAuctionSize(event.target.value)
          }
        />
      </div>

      <div className={styles.wrapperBottom}>
        <div className={styles.label}>End of Auction</div>
        <div className={styles.endDate}>{endDate}</div>
      </div>

      <button className={styles.btn} onClick={startAuction}>
        Start Auction
      </button>
    </MuiModal>
  );
};
