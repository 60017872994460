export const dateTimeStyles = {
  textField: {
    fullWidth: true,
    sx: {
      background: '#f5f5f7',
      borderRadius: '5px',
      color: '#101828',
      '& .MuiInputBase-input': {
        color: '#101828 !important', // Change text color
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Remove border
        color: '#101828',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Remove hover effect
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Remove focus effect
      },
      '&.Mui-focused': {
        boxShadow: 'none', // Remove focus shadow
      },
    },
  },
  sx: {
    '& .MuiOutlinedInput-input': {
      color: '#101828 !important', // Force text color inside input
    },
    '& .MuiClock-root': {
      height: '250px',
    },
    '& .MuiPickersTimePickerToolbar-ampmSelection': {
      gap: '4px', // Reduce spacing between AM/PM
    },
    '& .MuiPickersTimePickerToolbar-hourMinuteLabel': {
      fontSize: '16px', // Reduce text size
    },
    '& .MuiList-root': {
      maxHeight: '150px', // Limit dropdown height to avoid long scrolling
      overflowY: 'auto', // Enable scrolling inside the list
    },
    '& .MuiDialog-paper': {
      maxHeight: '300px', // Reduce total modal height to prevent excess scroll
    },
    '& .MuiPickersLayout-contentWrapper': {
      maxHeight: '250px', // Ensure content inside is scrollable but limited
    },
  },
};

export const DURATION_OPTIONS = [
  { label: '1 Day (24h)', value: '24' },
  { label: '2 Days (48h)', value: '48' },
  { label: '3 Days (72h)', value: '72' },
  { label: '4 Days (96h)', value: '96' },
  { label: '5 Days (120h)', value: '120' },
  { label: '6 Days (148h)', value: '148' },
];
