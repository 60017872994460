import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId:
        process.env.REACT_APP_API_ENV === 'local'
          ? 'local_1TGJtB8x'
          : process.env.REACT_APP_API_ENV === 'staging'
            ? 'us-west-1_RTwDQf8la'
            : process.env.REACT_APP_API_ENV === 'prod'
              ? 'us-east-2_nxU1rv6ma'
              : 'undefined',
      userPoolClientId:
        process.env.REACT_APP_API_ENV === 'local'
          ? '1xw5i7071pkyplqijmknl1179'
          : process.env.REACT_APP_API_ENV === 'staging'
            ? '4cc5nac7nq5r480ho1n8viv73b'
            : process.env.REACT_APP_API_ENV === 'prod'
              ? '5rape27ni061fp8jjjk2hndhj1'
              : 'undefined',
      ...(process.env.REACT_APP_API_ENV === 'local' && {
        userPoolEndpoint: 'http://localhost:9229/',
      }),
    },
  },
});
