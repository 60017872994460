import { Coupon } from 'src/redux/slices/coupons/types';
import api from './baseApi';

export const adminPanelApi = {
  getCoupons: () => {
    return api.get(`/coupons`);
  },

  getCouponsByCode: (code: string) => {
    return api.get(`/coupons/${code}`);
  },

  editCoupon: (id: number, data: Partial<Coupon>) => {
    return api.patch(`/coupons/${id}`, {
      data,
      // status,
      // max_users,
      // startDate,
      // endDate,
      // clientName,
      // salesNotes
    });
  },

  createCoupon: (
    clientName: string,
    max_users: number,
    startDate: string,
    endDate: string
  ) => {
    // note: can pass in `salesNotes` that'll be private to admins
    return api.post(`/coupons/`, {
      max_users,
      startDate,
      endDate,
      clientName,
    });
  },
};
