import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import MaterialTextField from 'src/components/material/textField';
import { fetchUser } from 'src/redux/slices/userSlice';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import { useUserLoginLogout } from 'src/hooks/UserLoginLogout/UserLoginLogout';
import styles from './LoginType.module.scss';
import { fetchLoggedUser } from 'src/redux/slices/loggedUserSlice';

interface LoginTypeProps {
  setStep: (step: number) => void;
  redirect?: string;
}

export interface IFormInput {
  phoneNumber: string;
  password: string;
}

export const LoginType: FC<LoginTypeProps> = ({ setStep, redirect }) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { login, loading } = useUserLoginLogout();

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<{
    phoneNumber?: string;
    newPassword?: string;
    general?: string;
  }>({});

  const validateInputs = () => {
    const errors: {
      phoneNumber?: string;
      newPassword?: string;
      general?: string;
    } = {};

    if (!phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    }

    if (!newPassword) {
      errors.newPassword = 'Password is required';
    } else if (newPassword.length < 6) {
      errors.newPassword = 'Password must be at least 6 characters long';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
    if (validationErrors.phoneNumber) {
      setValidationErrors((prev) => ({ ...prev, phoneNumber: undefined }));
    }
  };

  const handlePasswordChange = (value: string) => {
    setNewPassword(value);
    if (validationErrors.newPassword) {
      setValidationErrors((prev) => ({ ...prev, newPassword: undefined }));
    }
  };

  const onSubmit = async () => {
    if (!validateInputs()) {
      console.error('Validation failed');
      return;
    }

    try {
      const result = await login({
        username: phoneNumber,
        password: newPassword,
      });

      if (result?.isSignedIn) {
        dispatch(fetchUser());
        dispatch(fetchLoggedUser());
        if (redirect && redirect.includes('auction')) {
          navigate(redirect);
        } else {
          navigate(PathRoutes.HomeRoute);
        }
      } else {
        setValidationErrors((prev) => ({
          ...prev,
          general: 'Invalid phone number or password',
        }));
      }
    } catch (err) {
      console.error('Login failed:', err);
      setValidationErrors((prev) => ({
        ...prev,
        general: 'An error occurred. Please try again later.',
      }));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.title}>Log in</div>
        <div>
          <MaterialTextField
            isPhoneNumber
            label="Phone Number"
            name="phoneNumber"
            id="phone-number"
            variant="outlined"
            error={!!validationErrors.phoneNumber}
            helperText={validationErrors.phoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePhoneNumberChange(e.target.value)
            }
            value={phoneNumber}
            color="primary"
          />
          <MaterialTextField
            label="New Password"
            name="newPassword"
            id="new-password"
            variant="outlined"
            isPassword
            error={!!validationErrors.newPassword}
            helperText={validationErrors.newPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePasswordChange(e.target.value)
            }
            value={newPassword}
            color="primary"
          />
          {validationErrors.general && (
            <div className={styles.error}>{validationErrors.general}</div>
          )}
          <div className={styles.forgetPassword} onClick={() => setStep(3)}>
            Forgot your password?
          </div>
          <button className={styles.btn} onClick={onSubmit} disabled={loading}>
            {loading ? 'Logging in...' : 'Log in'}
          </button>
        </div>
        <div className={styles.border} />
        <div className={styles.footer}>
          Don't have an account?{' '}
          <div className={styles.link} onClick={() => setStep(2)}>
            Sign up
          </div>
        </div>
      </div>
    </div>
  );
};
