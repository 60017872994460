import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useState } from 'react';
import { circleApi } from 'src/api/circles';
import { DunbarPhoneInput } from 'src/components/common/PhoneInput/PhoneInput';
import MaterialTextField from 'src/components/material/textField';
import styles from './AddMember.module.scss';
interface AddMemberProps {
  setShowAddMember: (show: boolean) => void;
  circleId: string;
  fetchMembers: () => void;
}

export const AddMember: React.FC<AddMemberProps> = ({
  setShowAddMember,
  circleId,
  fetchMembers,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [role, setRole] = useState<string>('MEMBER');

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
  };

  const addMember = async () => {
    setError('');
    // remove space & add + in front of phone number
    const updatedPhone = `+${phoneNumber.replace(/\s+/g, '')}`;
    const updatedEmail = email.replace(/\s+/g, '');
    try {
      if (updatedPhone) {
        await circleApi.inviteMemberByPhone(circleId, role, updatedPhone);
      } else if (email) {
        await circleApi.inviteMemberByEmail(circleId, role, updatedEmail);
      } else {
        console.error(
          'Please provide either a phone number or an email address.'
        );
        setError('Please provide either a phone number or an email address.');
      }
      fetchMembers();
      setShowAddMember(false);
    } catch (error: any) {
      console.error('Failed to add member:', error);
      setError(error.response.data.message);
    }
  };
  return (
    <div className={styles.container}>
      <div onClick={() => setShowAddMember(false)}>
        <KeyboardBackspaceIcon sx={{ color: 'black', cursor: 'pointer' }} />
      </div>
      <FormControl component="fieldset" style={{ marginTop: '16px' }}>
        <RadioGroup
          value={role}
          onChange={handleRoleChange}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FormControlLabel
            value="MEMBER"
            control={<Radio />}
            label="Member"
            className={styles.description}
          />
          <FormControlLabel
            value="COLEADER"
            control={<Radio />}
            label="Co-leader"
            className={styles.description}
          />
        </RadioGroup>
      </FormControl>
      <DunbarPhoneInput
        title="Phone Number"
        isRequired
        value={phoneNumber}
        onChange={(value: string) => setPhoneNumber(value)}
        helperText={error}
        containerCustomClass={styles.phoneInputContainer}
        disabled={false}
      />
      <div className={styles.or}>OR</div>
      <MaterialTextField
        label="Email"
        variant="outlined"
        name="email"
        id="email"
        value={email}
        onChange={(e: any) => setEmail(e.target.value)}
        color="primary"
      />
      {error && <div className={styles.error}> {error}</div>}
      <button onClick={addMember} className={styles.btn}>
        Add Member
      </button>
    </div>
  );
};
