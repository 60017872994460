import { postsApi } from 'src/api/postst';
import { fetchPostsById } from 'src/redux/slices/feedSlice';
import toast from 'react-hot-toast';
import { AppDispatch } from 'src/redux/store/store';
import { poll } from 'src/utils/pooling';

export const startPollingForVideoUrl = (
  videoId: string,
  circleId: string,
  dispatch: AppDispatch
) => {
  poll(
    () =>
      postsApi.getPostsForCircle(circleId, 1).then((res) => res.data.result),
    {
      shouldStop: (posts) =>
        posts.some((post: any) => post.video_id === videoId && post.video_url),
      onSuccess: () => {
        toast.success('Your video is now available!');
        dispatch(fetchPostsById({ id: circleId, page: 1 }));
      },
      onError: () => {
        toast.error('Video upload timed out. Try again later.');
      },
      intervalMs: 4000,
      maxTries: 36,
    }
  );
};
