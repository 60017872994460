import animals from 'src/assets/images/categories/animals_dark.png';
import animalsLight from 'src/assets/images/categories/animals_light.png';
import art from 'src/assets/images/categories/art_dark.png';
import artLight from 'src/assets/images/categories/art_light.png';
import business from 'src/assets/images/categories/business_dark.png';
import businessLight from 'src/assets/images/categories/business_light.png';
import coaching from 'src/assets/images/categories/coaching_dark.png';
import coachingLight from 'src/assets/images/categories/coaching_light.png';
import creativity from 'src/assets/images/categories/creativity_dark.png';
import creativityLight from 'src/assets/images/categories/creativity_light.png';
import design from 'src/assets/images/categories/design_dark.png';
import designLight from 'src/assets/images/categories/design_light.png';
import education from 'src/assets/images/categories/education_dark.png';
import educationLight from 'src/assets/images/categories/education_light.png';
import finance from 'src/assets/images/categories/finance_dark.png';
import financeLight from 'src/assets/images/categories/finance_light.png';
import food from 'src/assets/images/categories/food_dark.png';
import foodLight from 'src/assets/images/categories/food_light.png';
import games from 'src/assets/images/categories/games_dark.png';
import gamesLight from 'src/assets/images/categories/games_light.png';
import health from 'src/assets/images/categories/health_dark.png';
import healthLight from 'src/assets/images/categories/health_light.png';
import hobbies from 'src/assets/images/categories/hobbies_dark.png';
import hobbiesLight from 'src/assets/images/categories/hobbies_light.png';
import hr from 'src/assets/images/categories/hr_dark.png';
import hrLight from 'src/assets/images/categories/hr_light.png';
import leadership from 'src/assets/images/categories/leadership_dark.png';
import leadershipLight from 'src/assets/images/categories/leadership_light.png';
import marketing from 'src/assets/images/categories/marketing_dark.png';
import marketingLight from 'src/assets/images/categories/marketing_light.png';
import mindfulness from 'src/assets/images/categories/mindfulness_dark.png';
import mindfulnessLight from 'src/assets/images/categories/mindfulness_light.png';
import music from 'src/assets/images/categories/music_dark.png';
import musicLight from 'src/assets/images/categories/music_light.png';
import nature from 'src/assets/images/categories/nature_dark.png';
import natureLight from 'src/assets/images/categories/nature_light.png';
import operations from 'src/assets/images/categories/operations_dark.png';
import operationsLight from 'src/assets/images/categories/operations_light.png';
import other from 'src/assets/images/categories/other_dark.png';
import otherLight from 'src/assets/images/categories/other_light.png';
import productivity from 'src/assets/images/categories/productivity_dark.png';
import productivityLight from 'src/assets/images/categories/productivity_light.png';
import relationships from 'src/assets/images/categories/relationships_dark.png';
import relationshipsLight from 'src/assets/images/categories/relationships_light.png';
import sales from 'src/assets/images/categories/sales_dark.png';
import salesLight from 'src/assets/images/categories/sales_light.png';
import spirituality from 'src/assets/images/categories/spirituality_dark.png';
import spiritualityLight from 'src/assets/images/categories/spirituality_light.png';
import sports from 'src/assets/images/categories/sports_dark.png';
import sportsLight from 'src/assets/images/categories/sports_light.png';
import strategy from 'src/assets/images/categories/strategy_dark.png';
import strategyLight from 'src/assets/images/categories/strategy_light.png';
import style from 'src/assets/images/categories/style_dark.png';
import styleLight from 'src/assets/images/categories/style_light.png';
import support from 'src/assets/images/categories/support_dark.png';
import supportLight from 'src/assets/images/categories/support_light.png';
import team from 'src/assets/images/categories/team_dark.png';
import teamLight from 'src/assets/images/categories/team_light.png';
import technology from 'src/assets/images/categories/technology_dark.png';
import technologyLight from 'src/assets/images/categories/technology_light.png';
import writing from 'src/assets/images/categories/writing_dark.png';
import writingLight from 'src/assets/images/categories/writing_light.png';

export const CATEGORIES = [
  {
    name: 'animals',
    img: animals,
    img_ligh: animalsLight,
  },
  {
    name: 'art',
    img: art,
    img_ligh: artLight,
  },
  {
    name: 'business',
    img: business,
    img_ligh: businessLight,
  },
  {
    name: 'coaching',
    img: coaching,
    img_ligh: coachingLight,
  },
  {
    name: 'creativity',
    img: creativity,
    img_ligh: creativityLight,
  },
  {
    name: 'design',
    img: design,
    img_ligh: designLight,
  },
  {
    name: 'education',
    img: education,
    img_ligh: educationLight,
  },
  {
    name: 'finance',
    img: finance,
    img_ligh: financeLight,
  },
  {
    name: 'food',
    img: food,
    img_ligh: foodLight,
  },
  {
    name: 'games',
    img: games,
    img_ligh: gamesLight,
  },
  {
    name: 'health',
    img: health,
    img_ligh: healthLight,
  },
  {
    name: 'hobbies',
    img: hobbies,
    img_ligh: hobbiesLight,
  },
  {
    name: 'hr',
    img: hr,
    img_ligh: hrLight,
  },

  {
    name: 'leadership',
    img: leadership,
    img_ligh: leadershipLight,
  },
  {
    name: 'marketing',
    img: marketing,
    img_ligh: marketingLight,
  },
  {
    name: 'mindfulness',
    img: mindfulness,
    img_ligh: mindfulnessLight,
  },
  {
    name: 'nature',
    img: nature,
    img_ligh: natureLight,
  },
  {
    name: 'operations',
    img: operations,
    img_ligh: operationsLight,
  },
  {
    name: 'music',
    img: music,
    img_ligh: musicLight,
  },

  {
    name: 'productivity',
    img: productivity,
    img_ligh: productivityLight,
  },
  {
    name: 'relationships',
    img: relationships,
    img_ligh: relationshipsLight,
  },
  {
    name: 'sales',
    img: sales,
    img_ligh: salesLight,
  },
  {
    name: 'spirituality',
    img: spirituality,
    img_ligh: spiritualityLight,
  },
  {
    name: 'sports',
    img: sports,
    img_ligh: sportsLight,
  },
  {
    name: 'style',
    img: style,
    img_ligh: styleLight,
  },

  {
    name: 'strategy',
    img: strategy,
    img_ligh: strategyLight,
  },
  {
    name: 'support',
    img: support,
    img_ligh: supportLight,
  },
  {
    name: 'team',
    img: team,
    img_ligh: teamLight,
  },
  {
    name: 'technology',
    img: technology,
    img_ligh: technologyLight,
  },
  {
    name: 'writing',
    img: writing,
    img_ligh: writingLight,
  },
  {
    name: 'other',
    img: other,
    img_ligh: otherLight,
  },
];
